.load-liquid-takes {
    table td[role="columnfooter"]{
        text-align: right;

        .footer-aggregate{
            font-size: 14px;
        }
    }
}

williams-ui-platform-load-liquid-takes {
    .k-detail-row, .k-detail-row.k-alt {
        background-color: rgba(127, 133, 220, 0.16);
        td {
            border-top: 1px solid $component-border;
            border-bottom: 1px solid $component-border;
        }
    }
    .k-footer-template {
        background-color: $primary;
        .k-grid-footer-sticky {
            background-color: $primary;
        }

        .footer-aggregate {
            color: $white;
        }

        td:has(.footer-aggregate), td:has(.footer-aggregate) + td {
            border-left: 0;
        }
    }
}