// // Breadcrumb
// $kendo-breadcrumb-margin-x: null !default;
// $kendo-breadcrumb-margin-y: null !default;
// $kendo-breadcrumb-padding-x: null !default;
// $kendo-breadcrumb-padding-y: null !default;
// $kendo-breadcrumb-border-width: 0px !default;

// $kendo-breadcrumb-font-family: $kendo-font-family !default;
// $kendo-breadcrumb-font-size: $kendo-font-size !default;
// $kendo-breadcrumb-line-height: $kendo-line-height !default;

//$kendo-breadcrumb-bg: $component-bg !default;
$kendo-breadcrumb-text: rgba(15, 34, 88,1) !default;
// $kendo-breadcrumb-border: $component-border !default;

// $kendo-breadcrumb-delimiter-icon-font-size: $icon-size * .75 !default;
// $kendo-breadcrumb-delimiter-icon-padding-x: $padding-x-lg / 2 !default;
// $kendo-breadcrumb-delimiter-icon-padding-y: 0px !default;

// $kendo-breadcrumb-link-padding-x: $padding-x !default;
// $kendo-breadcrumb-link-padding-y: $padding-y-lg !default;
// $kendo-breadcrumb-link-border-radius: $kendo-border-radius-md !default;

// $kendo-breadcrumb-icon-link-padding-x: $kendo-breadcrumb-link-padding-x !default;
// $kendo-breadcrumb-icon-link-padding-y: $kendo-breadcrumb-icon-link-padding-x !default;

// $kendo-breadcrumb-root-link-spacing: $kendo-breadcrumb-link-padding-x !default;
// $kendo-breadcrumb-link-icon-spacing: $icon-spacing !default;

// $kendo-breadcrumb-link-initial-text: inherit !default;

// $kendo-breadcrumb-link-bg: null !default;
 $kendo-breadcrumb-kendo-link-text: rgba(15, 34, 88, 0.8) !default;
// $kendo-breadcrumb-link-border: null !default;

// $kendo-breadcrumb-link-hovered-bg: $base-bg !default;
// $kendo-breadcrumb-link-hovered-text: $kendo-link-hover-text !default;
// $kendo-breadcrumb-link-hovered-border: null !default;

// $kendo-breadcrumb-link-focused-bg: null !default;
// $kendo-breadcrumb-link-focused-text: $kendo-link-text !default;
// $kendo-breadcrumb-link-focused-border: null !default;
// $kendo-breadcrumb-link-focused-shadow: $focused-shadow !default;

// $kendo-breadcrumb-root-link-bg: null !default;
// $kendo-breadcrumb-root-kendo-link-text: null !default;
// $kendo-breadcrumb-root-link-border: null !default;

// $kendo-breadcrumb-root-link-hovered-bg: $base-bg !default;
// $kendo-breadcrumb-root-link-hovered-text: null !default;
// $kendo-breadcrumb-root-link-hovered-border: null !default;

// $kendo-breadcrumb-root-link-focused-bg: null !default;
// $kendo-breadcrumb-root-link-focused-text: null !default;
// $kendo-breadcrumb-root-link-focused-border: null !default;
// $kendo-breadcrumb-root-link-focused-shadow: $kendo-breadcrumb-link-focused-shadow !default;

// $kendo-breadcrumb-focused-shadow: 0 0 2px 1px rgba(0, 0, 0, .06) !default;
