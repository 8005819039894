
@mixin wFill( $color: null, $bg: null, $border: null, $gradient: null ) {
    @include fill(
        $color,
        null,
        $border,
        $gradient
);
   
    @if $bg {

        background: $bg;
    }
    
    
    
}

// @mixin wBox-shadow( $shadow... ) {
//     @if $enable-shadows and nth($shadow, 1) {
//         box-shadow: $shadow;
//     }
// }

