williams-ui-platform-inactive-user-header,
williams-ui-platform-ext-header{
    .outer {
        display: flex;
        width: 100%;
        height: $ui-shell-header-height;
        background-color: $primary;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        z-index: 99999;
      
        .logo {
          position: relative;
          margin-left: map-get($spacing, 5);
          margin-top: map-get($spacing, 3);
          margin-bottom: map-get($spacing, 3);
        }
        .header-right-item {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
        .clock {
          color: $component-text-white;
          margin-right: 5.33px;
        }
      }
      
      .pad {
        padding-right: map-get($spacing, 2);
        padding-left: map-get($spacing, 2);
        padding-top: map-get($spacing, 3);
        padding-bottom: map-get($spacing, 3);
        color: $component-text-white;
      }
      .pad-right-item {
        padding-right: map-get($spacing, 5);
        padding-left: map-get($spacing, 2);
        padding-top: map-get($spacing, 3);
        padding-bottom: map-get($spacing, 3);
      }
      .bar {
        color: $button-text;
        opacity: .5;
      }

      kendo-multicolumncombobox.k-input-solid {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        .k-input-button {
          background-color: $primary;
          color: $white;
        }
      }

      williams-ui-platform-multi-column-combobox kendo-multicolumncombobox {
        max-width: 400px;
      }
}


william-ui-platform-header {
  .outer {
    width: 100%;
    height: $ui-shell-header-height;
    background-color: $primary;
    position: fixed;
    z-index: 99999;
    color: $component-text-white;

    .mat-icon {
      height: auto;
      width: auto;
      font-size: map-get($spacing, 4);
    }

    .separator {
      width: 1px;
      height: 20px;
      background-color: rgba(11, 30, 37, 0.5);
    }
    williams-ui-platform-multi-column-combobox {
      max-width: 400px;
    }
    kendo-multicolumncombobox.k-input-solid {
      background-color: $primary;
      color: $white;
      border-color: $primary;
      .k-input-button {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

williams-ui-platform-single-agreement{
  .w-flex-shrink-0{
    flex-shrink: 0;
    flex-grow: 1;
  }

  .w-flex-grow-1 {
    flex-grow: 1;
  }

  .overflow-auto-width-804 {
    overflow: auto;
    width: 72%;
  }

  .w-b-t-b-solid {
    border-top: 1px solid #C2C2C5;
    border-bottom: 1px solid #C2C2C5;
  }
}

williams-ui-platform-batman-summary, williams-ui-platform-batman-tolerance, williams-ui-platform-dependent-process-tolerance{
  .summary-agreement {
    .k-stack-layout-primary{
      border-radius : 12px
    }
  }

  h5{
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .18px;
    font-style: normal;
    line-height: 24px;
    color: var(--component-text, #0B1E25);
    font-feature-settings: 'clig' off, 'liga' off;
  }
  h6{
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .18px;
    font-style: normal;
    line-height: 20 px;
    color: var(--component-text, #0B1E25);
    font-feature-settings: 'clig' off, 'liga' off;
  }
  .w-bt-1-solid {
    border-top: 1px solid #C2C2C5;
  }
}

williams-ui-platform-capacity-allocation-tabs{
  .extended-grid-wrapper {
    filter: unset;
  }
}


