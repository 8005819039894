williams-ui-platform-nav-bar {
  .nav-container{
   flex-basis: 250px;
  }
  .nav-bar-icons {
    margin-left: map-get($spacing, 1);
  }

  .k-treeview-leaf {
    .k-treeview-leaf-text {
      span {
        display: inline-block !important
      }
    }
  }
}

.panelbar-wrapper {
  width: $nav-bar-menu-width;
  flex-direction: column;
  justify-content: space-between;
}

.root-leaf{
  padding-left: map-get($spacing, 2);
}

.child-leaf{
  padding-left: map-get($spacing, 4);
  font-weight: $kendo-font-weight-normal;
}

.k-treeview-leaf-text img {
  height: 16px;
  width: 16px;
}
